import {useLocation, useParams} from "react-router-dom";
import {LoaderWithFetch} from "../components/Loader";
import Markdown from "react-markdown";
import {Components} from "react-markdown/lib";
import {createElement} from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Helmet} from "react-helmet-async";

interface BlogEntry {
    url: string,
    timestamp?: number,
    shortTitle: string,
    shortDescription?: string,
    markdown: string,
    category?: string,
    bannerImageUrl?: string,
    ogImageUrl?: string
    headings?: Heading[]
}

interface Heading {
    id: string,
    level: number
    title: string,
}

export default function BlogEntryPage() {
    const params = useParams()
    const url = params.id!!
    const location = useLocation()

    let blogParams = new URLSearchParams({url: url});
    let components: Components = {}
    for (let i = 1; i <= 6; i++) {
        const name = `h${i}` as keyof Components
        // @ts-ignore
        components[name] = ({node, ...props}) => {
            let newProps = {...props}
            if (props.children) {
                newProps.id = props.children.toString().toLowerCase().replaceAll(/[^a-z0-9]+/g, "");
            }
            return (
                createElement(`h${i}`, newProps, props.children)
            )
        }
    }

    return (
        <LoaderWithFetch<BlogEntry> url={`/api/v1/blogEntry?${blogParams}`}>
            {
                (response) => {
                    const scrollToHeading = (id: string) => {
                        const element = document.getElementById(id)
                        if (element) {
                            window.scrollTo({
                                top:
                                    element.getBoundingClientRect().top -
                                    document.body.getBoundingClientRect().top -
                                    50,
                            })
                        }
                    }
                    return (
                        <div className="container-lg">
                            <Helmet prioritizeSeoTags>
                                <title>{response.shortTitle}</title>
                                <meta name="og:title" key="og:title" content={response.shortTitle}/>
                                <meta name="og:description" key="og:description" content={response.shortDescription}/>
                                <meta name="og:image" key="og:image" content={response.ogImageUrl}/>
                                <meta name="og:url" key="og:url" content={window.location.host + location.pathname}/>
                                <meta name="og:type" key="og:type" content="article"/>
                                <meta name="og:publish_date" key="og:publish_date" content={response.timestamp ? new Date(response.timestamp).toISOString() : ""}/>
                            </Helmet>
                            <div className="post-section rounded-xs">
                                <div className="w-layout-grid column-xl">
                                    <div className="text-2xl">{response.shortTitle}</div>
                                    <div className="image-wrapper rounded-xs">
                                        {
                                            response.bannerImageUrl && (
                                                <img src={response.bannerImageUrl} loading="lazy" alt=""
                                                     className="image-cover"/>
                                            )
                                        }
                                    </div>
                                    <div className="container-md-copy">
                                        <div className="table-of-contents">
                                            <Card className={"m-2"}>
                                                <CardHeader>
                                                    <h3>Jump To</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <div>
                                                        <ul>
                                                            {
                                                                response.headings && response.headings.map(heading => (
                                                                    <li className={`toc-level-${heading.level}`} key={heading.id}>
                                                                        <div className={"cursor-pointer"}
                                                                             onClick={() => scrollToHeading(heading.id)}>{heading.title}</div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="blog-entry-content">
                                            <div className="rich-text-block w-richtext">
                                                <Markdown components={components}>{response.markdown}</Markdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        </LoaderWithFetch>
    )
}