import {LoaderWithFetch} from "../components/Loader";
import {useNavigate} from "react-router-dom";


interface BlogOverviewEntry {
    shortTitle: string,
    shortDescription?: string,
    category?: string,
    ogImageUrl?: string,
    url: string,
    timestamp: string
}

interface BlogEntries {
    entries: BlogOverviewEntry[]
}

export default function BlogListPage() {
    let navigate = useNavigate();
    return (
        <>
            <LoaderWithFetch<BlogEntries> url={"/api/v1/blogList"}>
                {response => {
                    return (
                        <div className="container">
                            <div className="blog-entries-container">
                                <h1 className="text-center mb-4">Latest Blog Entries</h1>
                                {
                                    response.entries.length === 0 && (
                                        <div className="text-center">No blog entries found</div>
                                    )
                                }
                                <div className={"blog-entries"}>
                                    {
                                        response.entries.map(entry => {
                                            // the (23, 30, 37) comes from --var(--neutral-800)
                                            return (
                                                <div className="blog-entry"
                                                     style={{
                                                         backgroundImage: "linear-gradient(to right, rgba(23, 30, 37, 0), rgba(23, 30, 37, 1)), url('" + entry.ogImageUrl + "')"
                                                     }}
                                                     onClick={() => navigate(`/blog/${entry.url}`)}
                                                     key={entry.url}
                                                >
                                                    <h3 className="blog-title">
                                                        {entry.shortTitle}
                                                    </h3>
                                                    {
                                                        entry.timestamp && `Published on ${new Date(entry.timestamp).toDateString()}`
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }}
            </LoaderWithFetch>
        </>
    )
}